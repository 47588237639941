<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="title">{{ title }}</span>
      </div>
    </div>

    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
    >
      <a-form-item label="申请状态" :required="true">
        <a-select
          v-decorator="[
            'status',
            {
              rules: [{ required: true, message: '请选择申请状态' }],
            },
          ]"
          style="width: 100%"
          show-search
          :filter-option="filterOption"
          :options="statusOptions"
          placeholder="请选择申请状态"
          allowClear
          @change="handleStatusChange"
        >
        </a-select>
      </a-form-item>

      <a-form-item label="营业执照" v-show="entity.status == 4">
        <a-upload
          v-decorator="[
            'fileList',
            {
              rules: [
                {
                  required: entity.status == 4,
                  message: '请上传营业执照',
                  type: 'array',
                },
              ],
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
            },
          ]"
          :action="uploadUrl"
          :multiple="true"
          :data="{ subdir: this.subdirName }"
          :showUploadList="{
            showPreviewIcon: true,
          }"
          @change="handleUploadChange"
        >
          <a-button type="primary"> <a-icon type="upload" /> 上传</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item
        label="下照日期"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-date-picker
          v-decorator="[
            'licenseDate',
            {
              rules: [
                { required: entity.status == 4, message: '请选择下照日期' },
              ],
            },
          ]"
          placeholder="请选择下照日期"
        />
      </a-form-item>

      <a-form-item
        label="统一社会信用代码"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-input
          v-decorator="[
            'unifiedSocialCreditCode',
            {
              rules: [
                {
                  required: entity.status == 4,
                  message: '请输入统一社会信用代码',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="名称"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: entity.status == 4, message: '请输入名称' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="注册资金(万元)"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-input-number
          :min="0"
          :step="0.01"
          v-decorator="[
            'registeredCapital',
            {
              rules: [
                { required: entity.status == 4, message: '请输入注册资金' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="组成形式"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-input
          v-decorator="[
            'compositionForm',
            {
              rules: [
                { required: entity.status == 4, message: '请输入组成形式' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="预计开票内容"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-textarea
          :rows="2"
          v-decorator="[
            'expectednvoiceContent',
            {
              rules: [
                { required: entity.status == 4, message: '请输入预计开票内容' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="经营范围"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-textarea
          :rows="2"
          v-decorator="[
            'businessScope',
            {
              rules: [
                { required: entity.status == 4, message: '请输入经营范围' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="经营场所"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-input
          v-decorator="[
            'businessLocation',
            {
              rules: [
                { required: entity.status == 4, message: '请输入经营场所' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="登记机关"
        v-show="entity.status == 4"
        :required="entity.status == 4"
      >
        <a-input
          v-decorator="[
            'registrationAuthority',
            {
              rules: [
                { required: entity.status == 4, message: '请输入登记机关' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="是否开公户" v-show="entity.status == 4">
        <a-switch
          v-decorator="['openPublicAccount', { valuePropName: 'checked' }]"
        />
      </a-form-item>
      <a-form-item label="是否完成税务登记" v-show="entity.status == 4">
        <a-switch
          v-decorator="['isCompletedTaxReg', { valuePropName: 'checked' }]"
        />
      </a-form-item>
      <a-form-item
        label="原因"
        v-show="entity.status == 3 || entity.status == 5"
        :required="entity.status == 3 || entity.status == 5"
      >
        <a-textarea
          :rows="2"
          v-decorator="[
            'remark',
            {
              rules: [
                {
                  required: entity.status == 3 || entity.status == 5,
                  message: '请输入原因',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
  
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  name: "individualBusinessDetail",
  data() {
    return {
      // 获取到的数据
      entity: { status: undefined },
      spinning: false,
      UserData: [],
      Enabled: false,
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      //状态类型
      statusOptions: [
        {
          label: "申请失败",
          value: 3,
        },
        {
          label: "申请成功",
          value: 4,
        },
        {
          label: "预审成功",
          value: 6,
        },
        {
          label: "申请中",
          value: 7,
        },
        // {
        //     label: '已注销',
        //     value: 5
        // },
      ],
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    this.form.setFieldsValue({
      name: this.name,
    });
    this.getData();
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      var that = this;
      if (that.id && that.id != "") {
        that.SendToService({
          url: "/api/services/app/IndividualBusiness/GetEditById",
          method: "get",
          params: {
            id: this.id,
          },
          success(res) {
            var data = res;
            if (data.status < 3) {
              data.status = undefined;
            }
            that.entity = data;
            if (res.licenseUrl != null && res.licenseUrl != "") {
              that.fileList = [
                {
                  uid: res.id,
                  name: "营业执照",
                  url: AppConsts.uploadBaseUrl + res.licenseUrl,
                },
              ];
              data.fileList = that.fileList;
            }
            that.form.setFieldsValue(data);
          },
        });
      }
    },
    // 上传组件 @change
    handleUploadChange(info) {
      if (info.file.status == "done") {
        this.businessDiscern(info.file.originFileObj);
      }
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
    },
    handleStatusChange(e) {
      this.entity.status = e;
      this.$nextTick(() => {
        this.form.validateFields(["fileList"], { force: e === 4 });
        if (e === 4) {
          this.form.setFieldsValue({
            licenseDate: moment().format("YYYY-MM-DD"),
          });
        }
      });
    },
    //营业执照识别
    businessDiscern(file) {
      var that = this;
      const formData = new FormData();
      formData.append("file", file);
      that.SendToService({
        url: "/api/services/app/IndividualBusiness/BusinessDiscern",
        method: "post",
        data: formData,
        success(res) {
          if (res) {
            var data = res;
            that.form.setFieldsValue({
              unifiedSocialCreditCode: data["社会信用代码"].words,
              name: data["单位名称"].words,
              registeredCapital:
                data["注册资本"].words == "无" ? 0 : data["注册资本"].words,
              compositionForm: data["组成形式"].words,
              businessScope: data["经营范围"].words,
              businessLocation: data["地址"].words,
              registrationAuthority:
                data["登记机关"].words == "无"
                  ? "张家界市武陵源区市场监督管理局"
                  : data["登记机关"].words,
            });
          }
        },
      });
    },
    /**
     * 提交表单
     */
    handleSubmit(e) {
      var that = this;
      e.preventDefault();
      that.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          var dto = { ...that.entity, ...values };
          if (values.fileList && values.fileList.length > 0) {
            dto = {
              ...dto,
              ...{
                licenseUrl: values.fileList[0].url.replace(
                  AppConsts.uploadBaseUrl,
                  ""
                ),
              },
            };
          } else {
            dto.licenseUrl = "";
          }
          that.SendToService({
            url: "/api/services/app/IndividualBusiness/SaveIndividualBusiness",
            method: "post",
            data: dto,
            success(res) {
              that.success(true);
            },
          });
        }
      });
    },
    normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      if (e.fileList.length > 0) {
        //单文件上传
        return e && [e.fileList[e.fileList.length - 1]];
      }
      return e && e.fileList;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    SendToService(opts) {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + opts.url,
        method: opts.method,
        data: opts.data,
        params: opts.params,
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api
        .request(options)
        .then((res) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(res);
          }
          if (res.status == 200) {
            if (opts.success && typeof opts.success == "function") {
              opts.success(res.data);
            }
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(e);
          }
          console.error(e);
        });
    },
  },
};
</script>
  
<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
  