var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.title)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"申请状态","required":true}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'status',
          {
            rules: [{ required: true, message: '请选择申请状态' }],
          } ]),expression:"[\n          'status',\n          {\n            rules: [{ required: true, message: '请选择申请状态' }],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.filterOption,"options":_vm.statusOptions,"placeholder":"请选择申请状态","allowClear":""},on:{"change":_vm.handleStatusChange}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"营业执照"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'fileList',
          {
            rules: [
              {
                required: _vm.entity.status == 4,
                message: '请上传营业执照',
                type: 'array',
              } ],
            valuePropName: 'fileList',
            getValueFromEvent: _vm.normFile,
          } ]),expression:"[\n          'fileList',\n          {\n            rules: [\n              {\n                required: entity.status == 4,\n                message: '请上传营业执照',\n                type: 'array',\n              },\n            ],\n            valuePropName: 'fileList',\n            getValueFromEvent: normFile,\n          },\n        ]"}],attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"showUploadList":{
          showPreviewIcon: true,
        }},on:{"change":_vm.handleUploadChange}},[_c('a-button',{attrs:{"type":"primary"}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传")],1)],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"下照日期","required":_vm.entity.status == 4}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'licenseDate',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请选择下照日期' } ],
          } ]),expression:"[\n          'licenseDate',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请选择下照日期' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请选择下照日期"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"统一社会信用代码","required":_vm.entity.status == 4}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'unifiedSocialCreditCode',
          {
            rules: [
              {
                required: _vm.entity.status == 4,
                message: '请输入统一社会信用代码',
              } ],
          } ]),expression:"[\n          'unifiedSocialCreditCode',\n          {\n            rules: [\n              {\n                required: entity.status == 4,\n                message: '请输入统一社会信用代码',\n              },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"名称","required":_vm.entity.status == 4}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: _vm.entity.status == 4, message: '请输入名称' }],
          } ]),expression:"[\n          'name',\n          {\n            rules: [{ required: entity.status == 4, message: '请输入名称' }],\n          },\n        ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"注册资金(万元)","required":_vm.entity.status == 4}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'registeredCapital',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请输入注册资金' } ],
          } ]),expression:"[\n          'registeredCapital',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请输入注册资金' },\n            ],\n          },\n        ]"}],attrs:{"min":0,"step":0.01}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"组成形式","required":_vm.entity.status == 4}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'compositionForm',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请输入组成形式' } ],
          } ]),expression:"[\n          'compositionForm',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请输入组成形式' },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"预计开票内容","required":_vm.entity.status == 4}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'expectednvoiceContent',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请输入预计开票内容' } ],
          } ]),expression:"[\n          'expectednvoiceContent',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请输入预计开票内容' },\n            ],\n          },\n        ]"}],attrs:{"rows":2}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"经营范围","required":_vm.entity.status == 4}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'businessScope',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请输入经营范围' } ],
          } ]),expression:"[\n          'businessScope',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请输入经营范围' },\n            ],\n          },\n        ]"}],attrs:{"rows":2}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"经营场所","required":_vm.entity.status == 4}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'businessLocation',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请输入经营场所' } ],
          } ]),expression:"[\n          'businessLocation',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请输入经营场所' },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"登记机关","required":_vm.entity.status == 4}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'registrationAuthority',
          {
            rules: [
              { required: _vm.entity.status == 4, message: '请输入登记机关' } ],
          } ]),expression:"[\n          'registrationAuthority',\n          {\n            rules: [\n              { required: entity.status == 4, message: '请输入登记机关' },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"是否开公户"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['openPublicAccount', { valuePropName: 'checked' }]),expression:"['openPublicAccount', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 4),expression:"entity.status == 4"}],attrs:{"label":"是否完成税务登记"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isCompletedTaxReg', { valuePropName: 'checked' }]),expression:"['isCompletedTaxReg', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity.status == 3 || _vm.entity.status == 5),expression:"entity.status == 3 || entity.status == 5"}],attrs:{"label":"原因","required":_vm.entity.status == 3 || _vm.entity.status == 5}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            rules: [
              {
                required: _vm.entity.status == 3 || _vm.entity.status == 5,
                message: '请输入原因',
              } ],
          } ]),expression:"[\n          'remark',\n          {\n            rules: [\n              {\n                required: entity.status == 3 || entity.status == 5,\n                message: '请输入原因',\n              },\n            ],\n          },\n        ]"}],attrs:{"rows":2}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }