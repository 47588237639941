<template>
  <a-spin :spinning="spinning">
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="data"
      :total-items="totalItems"
      :actions-type="actionsType"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitLinkClick="linkClick"
    >
      <!-- 查询 -->
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.realName"
            placeholder="姓名"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.idCard"
            placeholder="身份证号"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.phoneNumber"
            placeholder="手机号"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-range-picker
            :placeholder="['开始时间', '结束时间']"
            v-model="startToEndDate"
          />
        </a-col>
        <a-col class="gutter-row" :span="2">
          <a-select
            v-model="SearchDto.status"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :options="statusOptions"
            placeholder="申请状态"
            allowClear
          >
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="getData()"
            v-if="isGranted('Pages.IndividualBusiness.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col :span="4" style="text-align: right">
          <a-button
            type="primary"
            v-if="isGranted('Pages.IndividualBusiness.ExportExcel')"
            @click="exportExcel"
          >
            导出Excel
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import {
  CommonServiceProxy,
  initFieldLibOptions,
} from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import detailModal from "./detailModal";


export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "individualBusiness-index",
  components: { EleTable },
  data() {
    return {
      spinning: false,
      selectedRowKeys: [],
      SearchDto: {
        realName: "",
        idCard: "",
        phoneNumber: "",
        startDate: "",
        endDate: "",
        status: 2,
        logoffing: false,
      },
      startToEndDate: [],
      // 总记录数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 总页数
      totalPages: 1,
      //
      pagerange: [1, 1],
      // 每页显示数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "CreationTime desc",
        maxResultCount: 50,
        skipCount: 0,
      },
      // 表头
      columns: [],
      actionsType: null,
      // 数据
      data: [],
      //状态类型
      statusOptions: [
        {
          label: "草稿",
          value: 1,
        },
        {
          label: "待申请",
          value: 2,
        },
        {
          label: "预审成功",
          value: 6,
        },
        {
          label: "申请中",
          value: 7,
        },
        {
          label: "申请失败",
          value: 3,
        },
        {
          label: "申请成功",
          value: 4,
        },
        {
          label: "已注销",
          value: 5,
        },
        {
          label: "注销中",
          value: -1,
        },
      ],
    };
  },
  created() {
    this.getData();
    this.initActionsType();
    this.initColums();
  },
  methods: {
    /**
     * 搜索框
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    initColums() {
      let _this = this;
      this.columns = [
        {
          title: "姓名",
          dataIndex: "realName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: "身份号",
          dataIndex: "idCard",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: "手机号",
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "phoneNumber" },
        },

        {
          title: "家庭住址",
          dataIndex: "address",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "address" },
        },
        {
          title: "身份证正面",
          dataIndex: "idCardPhotoFront",
          sorter: false,
          align: "center",
          type: "link",
          linkLabel: "查看",
          scopedSlots: { customRender: "idCardPhotoFront" },
        },
        {
          title: "身份证反面",
          dataIndex: "idCardPhotoVerso",
          sorter: false,
          align: "center",
          type: "link",
          linkLabel: "查看",
          scopedSlots: { customRender: "idCardPhotoVerso" },
        },
        {
          title: "App注册截图",
          dataIndex: "appImageUrl",
          sorter: false,
          align: "center",
          type: "link",
          linkLabel: "查看",
          scopedSlots: { customRender: "appImageUrl" },
        },
        {
          title: "申请日期",
          dataIndex: "creationTime",
          sorter: true,
          align: "center",
          type: "date",
          scopedSlots: { customRender: "creationTime" },
        },
        {
          title: "申请状态",
          dataIndex: "status",
          sorter: false,
          align: "center",
          type: "custom",
          scopedSlots: { customRender: "status" },
          customRender: function (text, record, index) {
            if (text == 1) {
              return "草稿";
            } else if (text == 2) {
              return "待申请";
            } else if (text == 3) {
              return "申请失败";
            } else if (text == 4) {
              return "申请成功";
            } else if (text == 5) {
              return "已注销";
            }else if(text == 6){
              return "预审成功";
            }else if(text == 7){
              return "申请中";
            }else if(text == -1){
              return "注销中";
            }
            return "";
          },
        },
        {
          title: "是否完成税务登记",
          dataIndex: "isCompletedTaxReg",
          sorter: false,
          align: "center",
          type: "custom",
          scopedSlots: { customRender: "isCompletedTaxReg" },
          customRender: function (text, record, index) {
            return text?'是':'否'
          },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          fixed: "right",
          width: 300,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "individualBusiness",
        isShow: true,
        fns: {
          detailList: [
            {
              granted: this.isGranted("Pages.IndividualBusiness.Edit"),
              showFunc(record) {
                return (
                  record.status > 1 &&
                  record.status !== 5 &&
                  _this.SearchDto.status !== -1
                );
              },
              name: "上传工商信息",
              //icon: "profile",
              fn: (data) => {
                ModalHelper.create(
                  detailModal,
                  { id: data.id, title: "上传工商信息" },
                  {
                    width: "1200px",
                  }
                ).subscribe((res) => {
                  if (res) {
                    _this.$notification["success"]({
                      message: "上传成功",
                    });
                    _this.getData();
                  }
                });
              },
            },
            {
              granted: this.isGranted("Pages.IndividualBusiness.FinishLogoff"),
              showFunc(record) {
                return record.status === 4;
              },
              name: "完成注销",
              //icon: "profile",
              fn: (data) => {
                abp.message.confirm(
                  "是否已线下完成注销请求",
                  "系统提示",
                  (result) => {
                    if (result) {
                        _this.SendToService({
                        url: "/api/services/app/IndividualBusiness/FinishLogoff",
                        method: "post",
                        data: { id: data.id },
                        success(res) {
                            _this.$notification["success"]({
                            message: "注销成功",
                          });
                          _this.getData();
                        },
                      });
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted(
                "Pages.IndividualBusiness.RevocationLogoff"
              ),
              showFunc(record) {
                return record.status === 5;
              },
              name: "撤销注销",
              //icon: "profile",
              fn: (data) => {
                _this.SendToService({
                  url: "/api/services/app/IndividualBusiness/RevocationLogoff",
                  method: "post",
                  data: { id: data.id },
                  success(res) {
                    _this.$notification["success"]({
                      message: "撤销成功",
                    });
                    _this.getData();
                  },
                });
              },
            },
          ],
          isShow: true,
        },
      };
      this.actionsType = obj;
    },
    getData() {
      let data = { ...this.request, ...this.SearchDto };
      if (this.startToEndDate.length > 1) {
        data.startDate = moment(this.startToEndDate[0]).format("YYYY-MM-DD");
        data.endDate = moment(this.startToEndDate[1]).format("YYYY-MM-DD");
      }
      if (data.status === -1) {
        data.logoffing = true;
        data.status = undefined;
      } else {
        data.logoffing = false;
      }
      let newData = {};
      // 循环对象，判断对象中某些属性是否存在，且排除属性值为空格的属性
      for (let key in data) {
        if (
          data[key] &&
          data[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
        ) {
          newData[key] = data[key];
        }
      }

      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      let _this = this;
      this.SendToService({
        url: "/api/services/app/IndividualBusiness/GetPaged",
        method: "get",
        params: newData,
        success(res) {
          _this.data.splice(0, _this.data.length);
          _this.totalItems = res.totalCount;
          res.items.forEach((item) => _this.data.push(item));
          _this.data = res.items.map((item) => {
            return {
              ...item,
            };
          });
        },
      });
    },
    refreshGoFirstPage() {
      this.SearchDto = {
        name: "",
        projectName: "",
        status: "",
        category: "",
        invoiceType: "",
        invoiceMonth: "",
        startDate: "",
        endDate: "",
      };
      this.pageNumber = 1;
      this.request.SkipCount = 0;
      this.getData();
    },
    linkClick(newV) {
      const { item, index } = newV;
      window.open(
        AppConsts.uploadBaseUrl + item[this.columns[index].dataIndex]
      );
    },

    exportExcel() {
      let data = {
        ...{
          sorting: this.request.sorting,
          skipCount: this.request.skipCount,
          maxResultCount: this.request.maxResultCount,
        },
        ...this.SearchDto,
      };
      if (this.startToEndDate.length > 1) {
        data.startDate = moment(this.startToEndDate[0]).format("YYYY-MM-DD");
        data.endDate = moment(this.startToEndDate[1]).format("YYYY-MM-DD");
      }
      let newData = {};
      // 循环对象，判断对象中某些属性是否存在，且排除属性值为空格的属性
      for (let key in data) {
        if (
          data[key] &&
          data[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
        ) {
          newData[key] = data[key];
        }
      }
      try {
        this.SendToService({
          url: "/api/services/app/IndividualBusiness/GetToExcelFile",
          method: "get",
          params: newData,
          success(res) {
            this.spinning = false;
            fileDownloadService.downloadTempFile(res);
          },
        });
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    SendToService(opts) {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + opts.url,
        method: opts.method,
        data: opts.data,
        params: opts.params,
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api
        .request(options)
        .then((res) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(res);
          }
          if (res.status == 200) {
            if (opts.success && typeof opts.success == "function") {
              opts.success(res.data);
            }
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(e);
          }
          console.error(e);
        });
    },
    /*
        分页相关
        */
    // 情况查询条件并重新查询
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.SearchDto.Name = "";
      this.SearchDto.FieldType = undefined;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(keys, rows) {
      if (!!this.selectedRows) {
        this.selectedRows.splice(0, this.selectedRows.length);
        for (let i = 0; i < rows.length; i++) {
          this.selectedRows.push(rows[i]);
        }
      }
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.SkipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}

.btn--footer {
  // border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-delete {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
